<template>
  <div>
    <page-header
      title="Statut des envois d'emails par millibase.net" icon="fa fa-plane-departure"
      :links="pageLinks">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!tagId">
      <b>Données non disponibles</b>
    </div>
    <div ref="pdfAndXls" v-if="tagId && loaded && !isLoading(loadingName)">
      <div class="my-header-bar">
        Nombre d'envois planifiés en attente
        <b-row>
          <b-col cols="3">
            <b>Votre structure:</b> <counter-label :counter="structureToBeSent" label="envoi"></counter-label>
          </b-col>
          <b-col cols="3">
            <b>Total:</b> <counter-label :counter="toBeSent" label="envoi"></counter-label>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="monthlyCounts.length > 0">
        <b-col :cols="6">
          <div class="monthly-counts">
            <b>Suivi mensuel des envois</b>
            <table class="table table-striped">
              <tr><th>Mois</th><th class="text-right">Nombre d'envois</th></tr>
              <tr v-for="elt of monthlyCounts" :key="elt.id">
                <td>{{ elt.month | dateToString('MMMM YYYY' )}}</td>
                <td class="number text-right">{{ elt.count }}</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
    <confirm-modal
      name="cancel-emails-sending"
      icon="fa fa-trash-o"
      title="Annuler vos envois en cours"
      :text="confirmText"
      @confirmed="onConfirmCancel"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader.vue'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import store from '@/store'
import { BackendApi, openDocument } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'email-sending-status',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'email-sending-status',
      loaded: false,
      toBeSent: 0,
      structureToBeSent: 0,
      monthlyCounts: [],
    }
  },
  async mounted() {
    await this.loadStats()
  },
  computed: {
    tagId() {
      return store.getters.config.tagId
    },
    canView() {
      return this.hasPerm('home.view_structure')
    },
    confirmText() {
      let text = this.structureToBeSent > 1 ? ('des ' + this.structureToBeSent + ' emails') : 'de l\'email'
      return 'Voulez-vous annuler l\'envoi ' + text + ' en attente pour votre structure'
    },
    pageLinks() {
      const cssClass = 'btn-secondary ' + (this.loaded ? '' : 'disabled ')
      const cssClass2 = 'btn-secondary ' + ((this.loaded && this.monthlyCounts.length > 0) ? '' : 'disabled ')
      const cssClass3 = 'btn-danger ' + ((this.loaded && this.structureToBeSent) ? '' : 'disabled ')
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: cssClass,
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: cssClass2,
        },
        {
          id: 3,
          label: 'Annuler vos envois',
          callback: this.cancel,
          icon: 'fa fa-trash-o',
          cssClass: cssClass3,
        }
      ]
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    cancel() {
      this.$bvModal.show('bv-confirm-modal:cancel-emails-sending')
    },
    async loadStats() {
      if (this.tagId) {
        let url = '/api/backends/email-sending-status/' + this.tagId + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.loaded = true
          this.toBeSent = resp.data['to_be_sent'] || 0
          this.structureToBeSent = resp.data['structure_to_be_sent'] || 0
          this.monthlyCounts = resp.data['monthly_counts'] || []
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onConfirmCancel() {
      let url = '/api/backends/email-sending-status/' + this.tagId + '/'
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi({ 'cancel': true, })
        this.toBeSent = resp.data['to_be_sent'] || 0
        this.structureToBeSent = resp.data['structure_to_be_sent'] || 0
        this.monthlyCounts = resp.data['monthly_counts'] || []
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = 'statut-envoi-emails'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
    async printMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          let docUrl = '/documents/standard/<key>/pdf/'
          const docSlug = 'statut-envoi-emails'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
  },
}
</script>

<style scoped>
.my-header-bar {
  padding: 20px;
  font-size: 24px;
  background: #f0f0f0;
}
.monthly-counts {
  margin: 20px 0;
}
</style>
